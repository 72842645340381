import React, { useEffect, useMemo, useState } from 'react';
import { NextComponentType } from 'next';

import '@styles/globals.css';
import Head from 'next/head';

import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { appWithTranslation, useTranslation } from 'next-i18next';

import { dehydrate, QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ReactQueryDevtools } from 'react-query/devtools';

import { useRouter } from 'next/router';
import { RecoilRoot } from 'recoil';
import { MONTH, SECOND } from '@constants/time';
import TempAccountManager from '@utils/TempAccountManager';
import ChannelTalkManager from '@utils/ChannelTalkManager';
import LanguageManager from '@utils/LanguageManager';
import PushManager from '@utils/PushManager';
import SentryManager from '@utils/SentryManager';
import { CustomToastConatiner } from '@components/_atoms/Toast';
import { Cookies } from 'react-cookie';
import { ACCESS_TOKEN, getCookie, LANGUAGE, removeCookie, SESSION_ID_KEY, setCookie } from '@utils/cookie';
import API from '@apis/client';
import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  PushStatusReadData,
  PushStatusReadError,
  UserGetUserProfileData,
  UserGetUserProfileError,
} from 'models/swagger/data-contracts';
import { setSentryDefaultAxiosErrorScope } from '@utils/SentryManager/setSentryDefaultAxiosErrorScope';
import sendUserActionLog from '@utils/log/userActionLog';
import 'ckeditor5/ckeditor5.css';

export const MyApp: NextComponentType<AppContext, AppInitialProps, AppProps> = ({ Component, pageProps }) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: SECOND * 60 * 1000, // ms 로 취급되어 1000 곱해줌
            cacheTime: Infinity,
            refetchOnWindowFocus: false,
            retry: 0,
            onError: (err: unknown) => {
              if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError<any>;

                setSentryDefaultAxiosErrorScope(axiosError);
              }
            },
          },
          mutations: {
            retry: 0,
            onError: (err: unknown) => {
              if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError<any>;

                setSentryDefaultAxiosErrorScope(axiosError);
              }
            },
          },
          // },
        },
      }),
  );

  //test ci/cd
  const router = useRouter();

  // 다국어 지원
  const { i18n, t } = useTranslation('common');
  const currentDomain = process.env.NEXT_PUBLIC_DOMAIN;
  const userActionLog = sendUserActionLog();

  useEffect(() => {
    // 페이지 변경 tracking
    fetch(`${router.asPath}`, { method: 'head' });
  }, [router]);


  // //점검코드
  // useEffect(() => {
  //   const prodTestCookie = getCookie("PROD_TEST_COOKIE")
  //   if(prodTestCookie) {
  //     return
  //   }
  //   // 이미 리다이렉트 페이지에 있는 경우 리다이렉트하지 않음
  //   if (router.pathname === '/') {
  //     return
  //   }
    
  //   // 모든 다른 경로를 홈페이지로 리다이렉트
  //   router.replace('/')
  // }, [router.pathname])

  /**
   * 7월 31일 이후 해당코드 제거 필요( dev서버에 접속시 기존에 생성되었던 ACCESS_TOKEN 제거 )
   */
  useEffect(() => {
    const accessToken = getCookie('ACCESS_TOKEN');

    if (!!accessToken && currentDomain === 'dev.steelboso.com') {
      removeCookie('ACCESS_TOKEN', { domain: currentDomain, path: '/' });
      removeCookie('REFRESH_TOKEN', { domain: currentDomain, path: '/' });
    }
  }, []);

  useEffect(() => {
    const getLanguage = getCookie(LANGUAGE);
    if (!getLanguage) {
      const language = location.pathname.startsWith('/en') ? 'en' : 'ko';
      setCookie(LANGUAGE, language, { maxAge: 3 * MONTH, domain: currentDomain, path: '/' });
    }
  }, []);

  useEffect(() => {
    const steelbosoSessionId = router.query['stlbs_session_id'];
    if (steelbosoSessionId) {
      setCookie(SESSION_ID_KEY, steelbosoSessionId, {
        maxAge: 12 * MONTH,
        domain: process.env.NEXT_PUBLIC_DOMAIN,
        path: '/',
      });
    }
  }, [router.query]);

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const body = window.location.search;

      const handleClickEvent = (event: Event) => {
        const clickedElement = event.target as Element;

        const targetElement = clickedElement.closest('[data-steelboso-id]');

        if (targetElement) {
          const steelbosoId = targetElement.getAttribute('data-steelboso-id');

          if (steelbosoId) {
            userActionLog('CLICK', {
              key: steelbosoId,
              body,
            });
          }
        }
      };

      document.body.addEventListener('click', handleClickEvent);

      return () => {
        // CLICK EVENT REMOVE
        document.body.removeEventListener('click', handleClickEvent);
      };
    }
  }, [pageProps]);

  useEffect(() => {
    const body = window.location.search;
    // PAGE IN LOG
    userActionLog('PAGE_IN', {
      body,
    });

    return () => {
      // PAGE OUT LOG
      userActionLog('PAGE_OUT', {
        body,
      });
    };
  }, [router.asPath]);

  useEffect(() => storePathValues, [router.asPath]);

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;

    const prevPath = storage.getItem('currentPath');

    if (prevPath) {
      storage.setItem('prevPath', prevPath);
    }

    storage.setItem('currentPath', globalThis.location.pathname);
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
        <meta name="title" content={t('global.title')} />
        <title>{t('global.title')}</title>
        <meta name="description" content={t('global.description')} />
        {/* 네이버 웹마스터도구 사이트 구조개선 */}
        {/* <meta name="NaverBot" content="All" />
        <meta name="NaverBot" content="index,follow" />
        <meta name="Yeti" content="All" />
        <meta name="Yeti" content="index,fllow" /> */}
        <meta
          name="keywords"
          content="마켓오브메테리얼,marketofmaterial,배관,파이프,수도배관자재,배관자재종류,피팅,배관자재,각파이프,파이프규격,피팅,철강,니플,레듀샤,체크밸브,후렌지,각파이프규격,엘보,스텐파이프,Flange,plant,steel,PIPE,프렌지,VALVE,SUS파이프,배관재,배관재 업체,배관재 견적,배관재 공급업체,배관재 온라인몰,배관재 온라인 거래,배관재 쇼핑몰,배관재 제작업체,배관재 납품업체,배관재 사는곳,Piping material,Piping material"
        />
        {/* 네이버 웹마스터도구 사이트 구조개선 end */}
        {/* Oepn Graph */}
        <meta property="og:type" content="website" /> */
        <meta property="og:title" content={t('global.title')} />
        <meta property="og:description" content={t('global.description')} />
        <meta property="og:url" content="https://steelboso.com" />
        <meta property="og:image" content="https://steelboso.com/assets/images/logo-og.jpg" />
        {/* Oepn Graph end*/}
        {/* <link rel="canonical" href="https://steelboso.com" /> */}
      </Head>

      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <LanguageManager>
              <TempAccountManager>
                <SentryManager>
                  <PushManager>
                    <ChannelTalkManager>
                      <Component {...pageProps} />
                      <CustomToastConatiner />
                    </ChannelTalkManager>
                  </PushManager>
                </SentryManager>
              </TempAccountManager>
            </LanguageManager>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
};

MyApp.getInitialProps = async ({ Component, ctx }: AppContext): Promise<AppInitialProps> => {
  const appProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
  const isClientRouting = ctx.req?.url?.startsWith('/_next');

  let pageProps = {};
  const cookies = new Cookies(ctx.req?.headers.cookie);
  const accessToken = cookies.get(ACCESS_TOKEN);
  const acceptLanguage = ctx.req?.headers['accept-language'];

  pageProps = { ...pageProps, acceptLanguage };

  if (isClientRouting) {
    return { ...appProps, pageProps };
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: !!accessToken,
      },
    },
  });

  try {
    if (!!accessToken) {
      await queryClient.fetchQuery(['user'], async () => {
        const userProfileData: AxiosResponse<UserGetUserProfileData, UserGetUserProfileError> = await API.get(
          `user/profile`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        return userProfileData.data.data;
      });

      await queryClient.fetchQuery(['getPushStatus'], async () => {
        const pushStatusData: AxiosResponse<PushStatusReadData, PushStatusReadError> = await API.get(`push/status`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        return pushStatusData?.data.data;
      });

      pageProps = { ...pageProps, dehydratedState: dehydrate(queryClient) };
    }
  } catch (e) {
    console.log(e);
  }

  return { ...appProps, pageProps };
};

export default appWithTranslation(MyApp);
